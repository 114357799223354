:root {
    --color-primary: #09013E;
    --color-secondary: #2584FF;
    --color-accent: #ff3400;
    --color-headings: #1B0760;
    --color-body: #575757;
    --color-background: #F5F6F9;
    --color-background-dark: #1E1E1E;
    --color-purple-main: #4A2B82;
    --color-purple-accent: #6B52A2;
    --border-radius: 20px;
    --color-purple-accent-light: #876dc0;
}
*, *::after, *::before {
    box-sizing: border-box;
   }
/* SCROLLBAR */
@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    display: none;
  }
}
::-webkit-scrollbar {
  width: 5px;
  overflow: overlay;
}
::-webkit-scrollbar-track {
  background:rgba(0, 0, 0, 0.04)
}
::-webkit-scrollbar-thumb {
  background: var(--color-purple-accent-light);
  transition: 0.5s;
  border-radius: 40px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-purple-accent);
}
/* TYPOGRAPHY */
html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}
body {
  font-family: 'Montserrat', sans-serif;
  color: var(--color-body);
  font-size: 2.4rem;
  line-height: 1.5;
  overflow-x: hidden;
  background-color: var(--color-background);
}
h1, h2, h3, h4, h5 {
  color: var(--color-headings);
  margin-bottom: 1rem;
  line-height: 1.1;
}
h2 {
  font-size: 4rem;
  text-align: center;
  margin: 6rem 0 4rem 0;
  color: var(--color-purple-accent);
}
h3 {
  font-size: 3rem;
}
p {
  font-size: 2rem;
}

/* REUSED */
.line {
  width: 60px;
  opacity: 0.6;
  margin-top: -4rem;
  margin-bottom: 4rem;
  height: 0;
  border: 1px solid var(--color-purple-accent-light);
  display: inline-block;
}
.bg__white {
  background-color: #fff;
}
.align-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
}
.margin--bottom {
  margin-bottom: 6rem;
}
.margin--top {
  margin-top: 4rem;
}
.padding--bottom {
  padding-bottom: 4rem;
}
.heading--title {
  margin-top: 1rem;
}
.container {
  padding-bottom: 10rem;
}
.side--padding {
  padding-right: 3rem;
  padding-left: 3rem;
}
.container--center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text--accent {
  color: var(--color-accent);
}

.banner {
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
}

.skewed--both {
  clip-path: polygon(0 2%, 100% 0%, 100% 98%, 0% 100%);
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.black__background {
  background-color: var(--color-background-dark);
  color: #fff;
}
.heading__light {
  color: #fff;
}

/* LINKS */
.link-purple {
  background-color: var(--color-purple-accent);
  color: #fff;
  border-radius: 20px;
  padding: 1rem 2rem 1rem 2rem;
  transition: 0.3s;
}
.link-purple:hover {
  color: #fff;
  box-shadow: 0 0 20px #8d6bcc62;
}
.hover__link {
  cursor: pointer;
  transition: 0.3s;
  font-size: 2rem;
}
.hover__link:hover {
  transform: scale(1.03);
}
/**/
.logo {
    width: 80px;
    height: auto;
    align-self: center;
    padding: 10px;
} 
  /* Hide the link that should open and close the topnav on small screens */
  .topnav .icon {
    display: none;
  }

/* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 600px) {
    .topnav a {display: none;}
    .topnav a.icon {
      display: block;
      padding: 0;
    }
  }
  
  /* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
  @media screen and (max-width: 600px) {
    .logo {
      width: 70px;
      align-self: center;
    }
    .topnav {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
    .topnav.responsive a.icon {
      justify-self: flex-end;
      margin-bottom: 1rem;
      padding: 0;
    }
    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
  }

/* ICONS */
.menu--icon {
  width: 40px;
  color: var(--color-purple-accent);
}
.icon {
  width: 40px;
  height: 40px;
}

.icon--title {
  width: 56px;
  margin: 0;
}
.icon--white {
  color: #fff;
}
.icon--red {
  color: var(--color-accent);
}
/* IMAGES */
.card__img {
  border-radius: var(--border-radius);
  margin-bottom: 2rem;
  box-shadow:
  0px 1.2px 3.6px rgba(0, 0, 0, 0.052),
  0px 3.3px 10px rgba(0, 0, 0, 0.075),
  0px 7.8px 24.1px rgba(0, 0, 0, 0.098),
  0px 26px 80px rgba(0, 0, 0, 0.15)
;
}
.img__upcoming {
  width: 92vw;
}
.video {
  width: 90vw;
  height: auto;
  border-radius: var(--border-radius);
}
.div--centered{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
/* HERO */
.hero__image {
  width: 100vw;
  object-fit: cover;
  z-index: 0;

}
.hero__background {
  width: 100vw;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary);
  clip-path: polygon(0 0, 100% 0%, 100% 95%, 0% 100%);
}

.hero__heading {
  font-size: 96px;
  color: #fff;
  opacity: 80%;
  margin-top: -8rem;
  margin-bottom: 0;
}
.hero__desc {
  color: #fff;
  margin-top: 0;
  margin-bottom: 10rem;
  padding: 0 2rem;
  text-align: center;
}

/* CARDS */
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: var(--border-radius);
  padding: 5rem 4rem 2rem 4rem;
  margin-top: 6rem;
  box-shadow:
  3.3px 3.3px 6.3px rgba(0, 0, 0, 0.04),
  11.2px 11.2px 21.2px rgba(0, 0, 0, 0.06),
  50px 50px 95px rgba(0, 0, 0, 0.1)
;
}
.card--contact {
  padding: 2rem;
  background-color: #ffff;
  width: 90vw;
  max-width: 600px;
}

.card--white {
  background-color: #fff;
}
.card--dark {
  background-color: #3d3d3d;
}

/* FOOTER */
.block--dark {
  background: #000;
  color: #7b858b;   
}

.grid {
  display: grid;
}

.footer__container {
  padding: 2rem 3rem;
}

.footer {
  background: #232323;
}
ul.footer--list {
  margin: 0;
}

.footer__section {
  margin: 0 2rem;
  padding: 0rem 2rem 1rem 2rem;
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow:
  4.5px 2.7px 4.1px rgba(0, 0, 0, 0.017),
  12.5px 7.5px 11.3px rgba(0, 0, 0, 0.025),
  30.1px 18.1px 27.1px rgba(0, 0, 0, 0.033),
  100px 60px 90px rgba(0, 0, 0, 0.05)
;
}

.footer__heading {
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-purple-main);
}

.footer__brand {
  margin-top: 5rem;
  text-align: center;
}

.footer__brand img {
  /* TODO: Consider refactoring this and applying it to all images. */
  width: 100%;
  max-width: 135px;
}

.footer__copyright {
  font-size: 2.1rem;
  color: #fff;
  opacity: 0.3;
}

.footer__section .list {
  margin: 0;
}

.footer a {
  color: #777;
  transition: color .3s;
}
.footer a:hover {
  color: #fff;
}
/* GALLERY */
.container--gallery {
  display: flex;
  width: 100%;
  padding: 4% 2%;
  box-sizing: border-box;
  height: 100vh;
}

.box {
  flex: 1;
  overflow: hidden;
  transition: .5s;
  margin: 0;
  box-shadow: 0 20px 30px rgba(0,0,0,.1);
  line-height: 0;
}

.box > img {
  width: 200%;
  height: 100%;
  object-fit: cover; 
  transition: .5s;
}

.box > span {
  font-size: 3.8vh;
  display: block;
  text-align: center;
  height: 10vh;
  line-height: 2.6;
}

.box:hover { flex: 1 1 50%; }
.box:hover > img {
  width: 100%;
  height: 100%;
}
/* GALLERY 2 */
/* Position the image container (needed to position the left and right arrows) */
.container--gallery-mobile {
  position: relative;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Add a pointer when hovering over the thumbnail images */
.cursor {
  cursor: pointer;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Container for image text */
.caption-container {
  text-align: center;
  background-color: #222;
  padding: 2px 16px;
  color: white;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Six columns side by side */
.column {
  float: left;
  width: 16.66%;
}

/* Add a transparency effect for thumnbail images */
.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}
/* BRANDS */
.brands {
  margin-top: 8rem;
}
.brands--layout {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.brand__img{
  width: 150px;
  filter: saturate(0%);
  transition: 0.3s;
  margin: 2rem 3rem;
}
.brand__img:hover {
  filter: saturate(100%);
  transform: scale(1.1);
}
.brand__smaller {
  width: 50px;
}
/* CONTACT */
.contact {
  color: var(--color-purple-accent-light);
  font-weight: 600;
  font-size: 2rem;
  padding-top: 2rem;
}
.contact--header {
  margin-top: 6rem;
}
/* BUTTONS */
.btn {
  border-radius: var(--border-radius);
  padding: 1.5rem;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  transition: 0.3s;
  margin: 2rem auto;
}
.btn:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 20px #6b52a25b;
}
.btn-purple {
  background-color: var(--color-purple-accent);
  color: #fff;
}
/* LISTS */
ul {
  list-style: none;
  text-decoration: none;
}
li {
  text-decoration: none;
}
.list {
  list-style: none;
  text-align: right;
}
.nav__list li:last-child {
  padding-bottom: 2rem;
}
/* NAVBAR */
.nav {
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(7px) saturate(160%) contrast(45%) brightness(140%);
  -webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
  position: fixed;
  z-index: 99;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 1rem;
}
.nav__toggler {
  cursor: pointer;
  transition: 0.3s ease-in;
}

.nav__list {
  width: 100%;
  margin: 0;
}
a {
  text-decoration: none;
}
.nav__item {
  padding: 0.6rem 2rem;
  text-decoration: none;
  color: var(--color-purple-accent-light);
}

.nav__item > a {
  color: var(--color-purple-main);
  transition: color 0.2s;
}
.nav__item > a:hover {
  color: var(--color-purple-accent-light);
}
.nav.collapsible--expanded .nav__toggler {
  color: var(--color-purple-main);
}

.nav__brand {
  width: 80px;
  transform: translateY(5px);
}

/* COLLAPSIBLES */
.collapsible__header {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.collapsible__heading {
  font-size: 2.2rem;
}
.collapsible__chevron {
  margin-top: 1.2rem;
  margin-left: 3rem;
  transform: rotate(-90deg);
  transition: transform 0.3s;
}
.collapsible__content {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: all 0.3s;
}
.collapsible--expanded .collapsible__chevron {
  transform: rotate(0);
}
.collapsible--expanded .collapsible__content {
  max-height: 100vh;
  opacity: 1;
  transition: all 0.3s;
}
.collapsible__img {
  width: 100%;
  margin-top: 2rem;
}
/* BEFORE AFTER SLIDER */
.container--slider { 
	width: 90vw;
  height: 90vw;
	margin: 4rem auto 0 auto;
	max-height: 400px;
	max-width: 600px;
	overflow: hidden;
}
/* SLIDER 1 */
.container--slider figure { 
	background-image: url(/src/images/before_floor.webp);
	background-size: cover;
	font-size: 0;
	height: 100%;
	margin: 0; 
  position: relative;
	width: 100%; 
}
#compare {
	background-image: url(/src/images/after_floor.webp);
	background-size: cover;
	bottom: 0;
	border-right: 5px solid rgba(255,255,255,0.7);
	box-shadow: 10px 0 15px -13px #000;
	height: 100%;
	max-width: 98.6%;
	min-width: 0.6%;
	overflow: visible;
	position: absolute;
	width: 100%; 
	animation: first 2s 1 normal ease-in-out 0.1s; 
	-webkit-animation: first 2s 1 normal ease-in-out 0.1s; 
}
input#slider {
	-moz-appearance: none;
	-webkit-appearance: none;
	border: none; 
	background: transparent;
	cursor: col-resize;
	height: 100vw;
	left: 0;
	margin: 0;
	outline: none; 
	padding: 0;
	position: relative;
	top: -100vw;
	width: 100%;
}
input#slider::-moz-range-track { 
	background: transparent; 
}
input#slider::-ms-track {
	border: none; 
	background-color: transparent;
	height: 100vw; 
	left: 0; 
	outline: none; 
	position: relative;
	top: -100vw; 
	width: 100%;
	margin: 0;
	padding: 0;
	cursor: col-resize;
	color:transparent;
}
input#slider::-ms-fill-lower {
	background-color:transparent;
}
input#slider::-webkit-slider-thumb {
	-webkit-appearance:none;
	height: 100vw;
	width: 0.5%;
	opacity: 0;
}
input#slider::-moz-range-thumb {
	-moz-appearance: none;
	height: 100vw;
	width: 0.5%;
	opacity: 0;
}   
input#slider::-ms-thumb {
	height: 100vw;
	width: 0.5%; 
	opacity:0;
}
input#slider::-ms-tooltip {
	display:none;
}
#compare::before {
	background: url(https://webdevtrick.com/wp-content/uploads/comparision.png) no-repeat scroll 0 center transparent;
	background-size:contain;
	content: " ";
	float: right;
	height: 100%;
	margin-right: -34px;
	position: relative;
	top:0;
	width: 64px;
}
#compare::before {
	background: url(https://webdevtrick.com/wp-content/uploads/comparision.png) no-repeat scroll 0 center transparent;
	background-size:contain;
	content: " ";
	float: right;
	height: 100%;
	margin-right: -34px;
	position: relative;
	top:0;
	width: 64px;
}
@keyframes first {
  0% {width: 0%; }
  50% {width: 80%; }
  100% {width: 50%; }
}
@-webkit-keyframes first {
  0% {width: 0%; }
  50% {width: 80%; }
  100% {width: 50%; }
}

/* MEDIA QUERRIES */

@media screen and (max-width: 768px) {
  .hidden-mobile {
    display: none;
  }
}

@media screen and (min-width: 769px) {
  .show-mobile {
    display: none;
  }
  .hidden-desktop {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .side--padding {
    padding-left: 15vw;
    padding-right: 15vw;
  }
  .hero__heading {
    font-size: 152px;
  }
  h3 {
    font-size: 2.8rem;
  }
  p {
    font-size: 1.8rem;
  }
  .hover__link {
    font-size: 1.8rem;
  }
  .icon--title {
    width: 44px;
  }
  .banner {
    width: 50%;
  }
  .banner__img {
    margin-top: 4rem;
    margin-left: 20vw;

  }
  .video {
    width: 70vw;
  }
  .footer__section {
    margin-left: 10vw;
    margin-right: 10vw;
  }
}

@media screen and (min-width: 1201px) {
  .hero__heading {
    font-size: 156px;
    margin-top: -13rem;
  }
  .hero__image {
    width: 100vw;
    height: 90vh;
    object-fit: cover;
  }
  .hero__desc {
    font-size: 3rem;
  }
  h2 {
    font-size: 3.6rem;
  }
  h3 {
    font-size: 2.6rem;
  }
  p {
    font-size: 1.6rem;
  }
  .icon--title {
    width: 40px;
  }
  .hover__link {
    font-size: 1.6rem;
  }
  .side--padding {
    padding-left: 6rem;
    padding-right: 6rem;;
  }
  .container__desktop {
    padding-left: 20vw;
    padding-right: 20vw;
  }
  .container__desktop--smaller {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  .container--slider {
    width: 250vw;
    margin: 6rem 5vw 0 2rem;
  }

  .card--center {
    display: flex;
    flex-direction: row;
  }
  .display-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
  }
  .display-center {
    text-align: center;
  }
  .card--white {
    margin-top: 8rem;
  }
  .card--dark {
    padding-bottom: 2rem;
    margin-bottom: 10rem;
  }
  .card__img {
    width: 25vw;
    margin-left: 4vw;
  }

  .video {
    width: 50vw;
    margin: 4rem 0 0 0;
  }
  .banner__img {
    width: 30vw;
    padding-bottom: 0;
  }
  .container {
    padding-bottom: 6rem;;
  }
  .container--studies {
    padding-left: 20vw;
    padding-right: 20vw;
  }
  .card__max-width {
    width: 500px;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .skewed--both {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .brands--layout {
    margin-top: 6rem;
  }

  .footer__heading {
    font-size: 2rem;
  }
  .collapsible__chevron {
    margin-top: 1.2rem;
  }
  .side-to-side {
    display: flex;
    flex-direction: row;
  }
  .contact--header {
    font-size: 3.6rem;
    color: var(--color-purple-main);
  }
  .contact-cards {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .card--contact {
    padding: 6rem 2rem;
    width: 40vw;
    max-width: 900px;
  }
  .nav-desktop {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(7px) saturate(160%) contrast(45%) brightness(140%);
    -webkit-backdrop-filter: blur(20px) saturate(160%) contrast(45%) brightness(140%);
    position: fixed;
    z-index: 99;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
  }
  .nav-desktop > ul {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .nav__item-desktop > a {
    font-size: 2rem;
    color: var(--color-purple-main);
    transition: 0.3s;
  }
  .nav__item-desktop > a:hover {
    color: var(--color-purple-accent-light);
  }
}
@media screen and (max-width: 1200px) {
  .hidden-1200 {
    display: none;
  }
}

@media screen and (min-width: 1201px) {
  .hidden-1201 {
    display: none;
  }
}